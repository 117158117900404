import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Pagination from "../components/common/Pagination";
import axios from "axios";
import ProgressModal from "../components/common/ProgressModal";
import {getBusinessDBList} from "../redux/reducers/businessDB";
import BusinessDBList from "../components/BusinessDBList";
import moment from "moment";


const BusinessDBContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.businessDB)
    const [searchQuery, setSearchQuery] = useState("");
    const [isWait, setIsWait] = useState(false)
    const [year, setYear] = useState(moment().year());
    const [month, setMonth] = useState(moment().format("MM"));
    const [DBTitles, setDBTitles] = useState([]);
    const [teamMember, setTeamMember] = useState(0);

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            adminNo: userInfo.userNo
        }))
        getDBTitle(year, month)
    }, [])


    const handlePageChange = (page) => {
        dispatch(getBusinessDBList({
            page: page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            adminNo: userInfo.userNo
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: query,
            titleNo: state.titleNo,
            adminNo: userInfo.userNo
        }))
    }

    const onYearHandler = (e) => {
        setYear(e.currentTarget.value)
    }
    const yearList = [
        {no: 1, value: 2023, name: "2023년"},
        {no: 2, value: 2024, name: "2024년"},
        {no: 3, value: 2025, name: "2025년"},
    ]
    const onMonthHandler = (year, e) => {
        setMonth(e.currentTarget.value)
        getDBTitle(year, e.currentTarget.value)
    }
    const monthList = [
        {no: 1, value: "01", name: "1월"},
        {no: 2, value: "02", name: "2월"},
        {no: 3, value: "03", name: "3월"},
        {no: 4, value: "04", name: "4월"},
        {no: 5, value: "05", name: "5월"},
        {no: 6, value: "06", name: "6월"},
        {no: 7, value: "07", name: "7월"},
        {no: 8, value: "08", name: "8월"},
        {no: 9, value: "09", name: "9월"},
        {no: 10, value: "10", name: "10월"},
        {no: 11, value: "11", name: "11월"},
        {no: 12, value: "12", name: "12월"}
    ]

    const onTitleHandler = (e) => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: e.currentTarget.value,
            adminNo: userInfo.userNo
        }))
    }

    const getDBTitle = (year, month) => {
        axios.post('/team/businessDBList/get_db_title',{
            year: year,
            month: month,
            admin_no: userInfo.userNo
        }).then((res) => {
            setDBTitles(res.data.businessDBTitle)
            console.log(res.data.businessDBTitle)
        })
    }

    const dbTitleDelete = () => {
        if(window.confirm("정말 반환합니까?")) {
            axios.post('/team/businessDBList/db_title_delete', {
                titleNo: state.titleNo,
                adminNo: userInfo.userNo
            }).then((res) => {
                if (res.data.isDeleted) {
                    alert("반환완료")
                    window.location.reload();
                } else {
                    alert("반환실패")
                }
            })
        }
    }

    const onClickSearch = (e) => {

        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            adminNo: userInfo.userNo
        }))
    }

    const sendBusinessList = (e) => {
        if(state.checkedInputs.length > 0) {
            axios.post('/team/businessDBList/send_business_list', {
                sendList: state.checkedInputs,
                admin_no: teamMember
            }).then((res) => {
                if(res.data.isUpdated) {
                    alert("전송완료")
                    window.location.reload();
                } else {
                    alert("전송실패")
                }
            })
        } else {
            alert("선택해주세요")
        }
    }

    const onTeamMemberHandler = (e) => {
        setTeamMember(e.currentTarget.value)
    }

    const gradeState = (level) => {
        switch(level) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return level
        }
    }

    const onRefresh = () => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            adminNo: userInfo.userNo
        }))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="d-flex w-50">
            <select className="form-select" onChange={onYearHandler} value={year} style={{width: "150px"}}>
                <option key={0} value={0}>---년도선택---</option>
                {yearList.map((item) => (
                    <option key={item.no} value={item.value}>{item.name}</option>
                ))}
            </select>
            <select className="form-select" onChange={(e) => onMonthHandler(year, e)} value={month} style={{width: "150px"}}>
                <option key={0} value={0}>---월선택---</option>
                {monthList.map((item) => (
                    <option key={item.no} value={item.value}>{item.name}</option>
                ))}
            </select>
            {month !== 0 && (
                <>
                    <select className="form-select" onChange={(e) => onTitleHandler(e)} value={state.titleNo} style={{width: "150px"}}>
                        <option key={0} value={0}>---목록선택---</option>
                        {DBTitles.map((item) => (
                            <option key={item.no} value={item.no}>{item.title}</option>
                        ))}
                    </select>
                    <button className="btn btn-sm btn-danger mx-1" onClick={dbTitleDelete}>목록 반환</button>
                </>
            )}
        </div>

        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={state.pageSize}
                onChange={e =>
                    dispatch(getBusinessDBList({
                        page: state.page,
                        pageSize: parseInt(e.target.value),
                        query: searchQuery,
                        titleNo: state.titleNo,
                        adminNo: userInfo.userNo
                    }))
                }
            >
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
                <option value={400}>400</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={10000}>10000</option>
            </select>
        </div>
        <div className="d-flex my-1">
            <div style={{
                width: "50%",
                display: "flex"
            }}>
                <p className="h5 ps-2 me-2">회원수: { startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>

                <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />
            </div>
            <div className="justify-content-end" style={{
                display: "flex",
                width: "50%"
            }}>
                <div className="input-group me-1 justify-content-end" style={{
                    display: "flex",
                    width: "300px"
                }}>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="검색어를 입력해주세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>

            </div>
        </div>
        <div className="d-flex justify-content-end">
            <select className="form-select" onChange={onTeamMemberHandler} value={teamMember} style={{width:"25%"}}>
                <option key={0} value={0}>---팀원선택---</option>
                {state.admins && state.admins.map((item, index) => (
                    <option key={item.value} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                ))}
            </select>

            <button className="btn btn btn-primary mx-1" style={{width:"60px"}}
                    onClick={sendBusinessList}>전송
            </button>
        </div>

        <BusinessDBList state={state} onRefresh={onRefresh} />
    </div>
}

export default BusinessDBContainer;