import React from 'react';
import BusinessContainer from "../../container/BusinessContainer";

const BusinessList = () => {

    return (
        <div className="userList">
            <p>영업목록</p>
            <BusinessContainer />
        </div>
    )
}

export default BusinessList;