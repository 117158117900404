import React, {useEffect, useState} from 'react';
import moment from "moment";
import axios from "axios";
import {Link} from "react-router-dom";

const Notice = () => {

    const [noticeList, setNoticeList] = useState([]);
    const [noticeCount, setNoticeCount] = useState(0);
    const [query, setQuery] = useState("");

    const getNoticeList = () => {
        axios.get('/team/notice/get_notice_list')
        .then((res) => {
            if(res.data.noticeList !== null) {
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)

            }
        })
    }
    useEffect(getNoticeList, [])

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }

    const searchHandler = (query) => {
        axios.post('/team/notice/query', {
            query: query,
        }).then((res) => {
            if (res.data.noticeList !== null) {
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)
            }
        })
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                공지사항
                <div className="d-flex my-auto">
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"200px"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "70px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <div className="my-auto">
                    <span>공지수 : {noticeCount && noticeCount[0].noticeCount} 개</span>
                </div>
            </div>
            <table className="table table-bordered border-dark text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "68%"}}>제목</th>
                    <th style={{width: "8%"}}>작성일자</th>
                </tr>
                </thead>
                {noticeList.map((notice, index) => (
                <tbody key={notice.no}>
                <tr>
                    <td>
                        <Link className="nav-link" to={"#noticeTitle" + index} data-bs-toggle="collapse"
                              aria-expanded="true" aria-controls={"noticeTitle" + index}>
                            {notice.title}
                        </Link>
                    </td>
                    <td>
                        {moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}
                    </td>
                </tr>
                <tr className="collapse" id={"noticeTitle" + index}>
                    <td colSpan="5" className="text-start">
                        {notice.cont}
                    </td>
                </tr>
                </tbody>
                ))}
            </table>

        </div>
    )
}

export default Notice;