import React, {useEffect, useState} from "react";
import {NumericFormat} from 'react-number-format';
import axios from "axios";
const TotalStatistics = () => {

    const [receive, setReceive] = useState(0);
    const [unReceive, setUnReceive] = useState(0);
    const [storeList, setStoreList] = useState([]);

    const testCount = () => {
        axios.get('/admin/statistics/get_receive_count')
        .then((res) => {
            setReceive(res.data.receiveCount)
            setUnReceive(res.data.unReceiveCount)
            setStoreList(res.data.storeList)
        })
    }
    useEffect(testCount, [])
    const sum = receive + unReceive

    const storeName = () => {
        axios.get('/admin/statistics/get_store_name')
            .then((res) =>{
                console.log(res.data.storeName)
            })
    }
    useEffect(storeName, [])


    return (
        <div className="p-2">
            상세통계 (팀장이랑 최고관리자 열람가능)
            <div className="container-fluid">
                <div className="d-flex justify-content-around mt-4">
                    <div className="bg-info" style={{width: "30%"}}>
                        <div className="ms-3">
                            <span className="fs-1">
                                <NumericFormat value={sum} displayType={'text'} thousandSeparator={true}/>
                            </span>
                        </div>
                        <div className="mx-3 pb-3">
                            <span className="fs-6 ">총 통화건수</span>
                        </div>
                    </div>
                    <div className="bg-success" style={{width: "30%"}}>
                        <div className="ms-3">
                            <span className="fs-1 text-white">
                                <NumericFormat value={receive? receive : 0} displayType={'text'} thousandSeparator={true}/>
                            </span>
                        </div>
                        <div className="mx-3 pb-3">
                            <span className="fs-6 text-white">수신통화건수</span>
                        </div>
                    </div>
                    <div className="bg-warning" style={{width: "30%"}}>
                        <div className="ms-3">
                            <span className="fs-1">
                                <NumericFormat value={unReceive? unReceive : 0} displayType={'text'} thousandSeparator={true}/>
                            </span>
                        </div>
                        <div className="mx-3 pb-3">
                            <span className="fs-6">미수신통화건수</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <table className="table table-bordered table-sm text-center align-middle mt-2">
                    <thead className="table-dark">
                    <tr>
                        <th>영업점</th>
                        <th>총 통화건수</th>
                        <th>수신통화건수</th>
                        <th>미수신통화건수</th>
                    </tr>
                    </thead>

                    {storeList.map((store, index) => (

                    <tbody key={store.no} className="border border-dark">
                    <tr>
                        <td>{store.name}</td>
                        <td>
                            <NumericFormat value={10000} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={4000} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={6000} displayType={'text'} thousandSeparator={true}/>
                        </td>
                    </tr>
                    </tbody>
                    ))}
                </table>
            </div>



        </div>
    )
}
export default TotalStatistics;