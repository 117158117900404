import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";

import Home from "./pages/home";
import Header from "./components/Header";

import User from "./pages/user"
import Board from "./pages/board"
import Statistics from "./pages/statistics"
import Setting from "./pages/setting"
import BusinessList from "./pages/user/businessList"
import BusinessDBList from "./pages/user/businessDBList"
import Notice from "./pages/board/notice";
import TotalStatistics from "./pages/statistics/total_statistics"
import Admin from "./pages/setting/admin"
import PhoneCheck from "./pages/setting/phoneCheck"

import LoginModal from "./components/LoginModal";

function App() {

  return (
    <div>
        <Routes>
            <Route element={<Layout />}>

                <Route path="/" element={<Home />}/>

                <Route path="user" element={<User />}>
                    <Route path="businessList" element={<BusinessList />}/>
                    <Route path="businessDBList" element={<BusinessDBList />}/>
                </Route>

                <Route path="board" element={<Board />}>
                    <Route path="notice" element={<Notice />}/>
                </Route>

                <Route path="statistics" element={<Statistics />}>
                    <Route path="total_statistics" element={<TotalStatistics />}/>
                </Route>

                <Route path="setting" element={<Setting />}>
                    <Route path="admin" element={<Admin />}/>
                    <Route path="PhoneCheck" element={<PhoneCheck />}/>
                </Route>

            </Route>
        </Routes>
    </div>
  );
}

function Layout() {

    const userInfo = useSelector((state) => state.userInfo);

    return (
        <div>
            {userInfo.isLogin?
                <div>
                    <Header />
                    <div>
                        <Outlet />
                    </div>
                </div>
                :
                    <LoginModal />
            }
        </div>
    )
}


export default App;
