import React from 'react';
import BusinessDBContainer from "../../container/BusinessDBContainer";

const BusinessDBList = () => {

    return (
        <div className="userList">
            <p>미할당목록</p>
            <BusinessDBContainer />
        </div>
    )

}

export default BusinessDBList;