import React, {useEffect, useState} from 'react';
import axios from "axios";
import AdminAddComponent from "../../components/AdminAddComponent";
import {useSelector} from "react-redux";

const Admin = () => {

    const [adminList, setAdminList] = useState([]);
    const [adminCount, setAdminCount] = useState(0);
    const [adminNos, setAdminNos] = useState([]);
    const [query, setQuery] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getAdminList = () => {
        axios.post('/team/admin/get_admin_list', {
            admin_no: userInfo.userNo
        }).then((res) => {
            if(res.data.adminList !== null) {
                setAdminList(res.data.adminList)
                setAdminCount(res.data.adminCount)
            }
        })
    }
    useEffect(getAdminList, [])

    const allCheckHandler = (e) => {
        if(adminList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < adminList.length; i++) {
                checks.push(adminList[i].no)
            }
            setAdminNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let adminNos2 = []
        if (checked) {
            adminNos2 = [...adminNos, no]
            setAdminNos([...adminNos, no])
        } else {
            adminNos2 = adminNos.filter((id) => id !== no)
            setAdminNos(adminNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for (let i = 0; i < adminNos2.length; i++) {
            totalCheck += adminList.filter((id) => id.no === adminNos2[i])[0].no
        }
    }

    const updateState = (state, adminNos) => {
        if(adminNos.length > 0) {
            axios.post('/team/admin/change_state', {
                adminNos: adminNos,
                state: state
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getAdminList()
            })
        }
    }

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        axios.post('/team/admin/query', {
            query: query,
            admin_no: userInfo.userNo
        }).then((res) => {
            if (res.data.queryList !== null) {
                setAdminList(res.data.queryList)
                setAdminCount(res.data.queryCount)
            } else {
                alert("실패")
            }
        })
    }

    function grade(grade) {
        switch(grade) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "마스터관리자"
            default : return grade
        }
    }

    function adminType(type) {
        switch(type) {
            case 1 :
                return "영업"
            case 2 :
                return "본사"
            case 3 :
                return "최고관리자"
            default : return type
        }
    }

    function adminState(state) {
        switch(state) {
            case 1 :
                return "사용"
            case 2 :
                return "중지"
            default : return state
        }
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                <div className="my-auto">
                    관리자관리
                </div>
                <div className="d-flex justify-content-end w-50">
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"35%"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "60px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div>관리자 수 : {adminCount}</div>
            <div className="d-flex border mt-2 justify-content-between">
                <div className="d-flex">
                    <button className="btn btn-success mx-1" onClick={() => updateState(1, adminNos)}>관리자사용</button>
                    <button className="btn btn-warning mx-1" onClick={() => updateState(2, adminNos)}>관리자중지</button>

                </div>
                <div>
                    <button className="btn btn-primary mx-1"
                            data-bs-target="#adminAdd" data-bs-toggle="modal">관리자추가</button>
                        <AdminAddComponent/>
                </div>
            </div>
            <table className="table table-bordered table-sm text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "3%"}}>
                        <input className="form-check-input" type="checkbox" checked={adminList.length === adminNos.length}
                               onChange={allCheckHandler} />
                    </th>
                    <th>구분</th>
                    <th>영업점</th>
                    <th>아이디</th>
                    {userInfo.grade === 3 ?
                    <th>비밀번호</th>
                        : <></> }
                    <th>권한</th>
                    <th>메모</th>
                    <th style={{width: "7%"}}>상태</th>
                </tr>
                </thead>
                {adminList && adminList.map((admin, index) => (
                <tbody key={admin.no}>
                <tr className="adminList">
                    <td>
                        <input className="form-check-input" type="checkbox"
                               checked={adminNos.filter((no) => no === admin.no).length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, admin.no)}} />
                    </td>
                    <td style={{width: "10%"}}>{adminType(admin.type)}</td>
                    <td>{admin.store.name}</td>
                    <td>{admin.id}</td>
                    {userInfo.grade === 3 ?
                        <td>{admin.pw}</td>
                        : <></> }
                    <td>{grade(admin.grade)}</td>
                    <td>{admin.memo}</td>
                    <td>{adminState(admin.state)}</td>
                </tr>
                </tbody>
                ))}
            </table>
        </div>
    )
}

export default Admin;