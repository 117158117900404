import {call, put,} from "redux-saga/effects";
import {failedGetBusinessList, setBusinessList} from "../reducers/business";
import {fetchBusinessList} from "../../lib/api";

export function* getBusinessListSaga(action) {
    try {
        const res = yield call(
            fetchBusinessList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.admin,
            action.payload.title,
            action.payload.receiveType,
        )
        console.log(res.data)
        yield put(setBusinessList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetBusinessList(error));
    }
}

