import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";

const AdminAddComponent = () => {

    const [store, setStore] = useState("");
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [grade, setGrade] = useState(1);
    const [memo, setMemo] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getStoreName = () => {
        axios.post('/team/admin/get_store_name', {
            admin_no: userInfo.userNo
        }).then((res) => {
            setStore(res.data.store)
        })
    }
    useEffect(getStoreName, [])
    const idChangeHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId)
        const notKorean = /[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentId)) {
            alert("한글,영어,숫자조합만 가능합니다")
            setId("")
        }
    }
    const pwChangeHandler = (e) => {
        setPw(e.currentTarget.value);
    }
    const gradeHandler = (e) => {
        setGrade(e.currentTarget.value);
    }
    const gradeSelectList = [
        {no: 1, value : 1, name : "팀원"},
        {no: 2, value : 2, name : "팀장"},
    ]
    const memoHandler = (e) =>{
        setMemo(e.currentTarget.value);
    }

    const handleRegister = () => {
        axios.post('/team/admin/insert', {
            id: id,
            pw: pw,
            type: userInfo.type,
            grade: grade,
            memo: memo,
            store_no: store.no
        }).then((res) => {
            if(res.data.isInserted) {
                alert('가입완료')
            }
            else {
                alert('가입실패')
            }
        })
    }
    return (

        <div className="modal fade" id="adminAdd" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >{store.name} 관리자추가</h1>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating mb-2">
                            <input className="form-control" maxLength="12" type="text" placeholder="아이디" value={id} onChange={idChangeHandler} />
                            <label className="col-form-label">아이디</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input className="form-control" maxLength="12" type="text" placeholder="비밀번호" value={pw} onChange={pwChangeHandler} />
                            <label className="col-form-label">비밀번호</label>
                        </div>
                        <div className="form-floating mb-2">
                            <select className="form-select" onChange={gradeHandler} value={grade}>
                                {gradeSelectList.map((item, index) => (
                                    <option key={item.no} value={item.value}>{item.name}</option>
                                ))}
                                {userInfo.grade === 3?
                                    <option key="3" value="3">최고관리자</option>
                                    : <></> }
                            </select>
                            <label className="col-form-label">권한</label>
                        </div>
                        <div className="form-floating">
                            <textarea className="form-control" maxLength="50" type="text" placeholder="메모" value={memo} onChange={memoHandler} />
                            <label className="col-form-label">메모</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminAddComponent;