import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"

import userInfoReducer from "./userInfo"
import businessDBReducer from "./businessDB"
import businessReducer from "./business"
import phoneCheckReducer from "./phoneCheck"

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    businessDB: businessDBReducer,
    business: businessReducer,
    phoneCheck: phoneCheckReducer,
});

export default persistReducer(persistConfig, rootReducer);