import axios from "axios";

export const requestLogin = async (id, pw) => {
    return axios.post("/api/sign/login", {id: id, pw: pw})
}

export function getTotalCount() {
    return axios.get('/team/phoneCheck/get_check_legnth', {
        params: {

        }
    })
}

export function searchPhone(query) {
    return axios.post('/team/phoneCheck/query', {
        query: query,
    })
}

//BusinessDB
export function fetchBusinessDBList(page, pageSize, query, titleNo, adminNo) {
    return axios.post('/team/businessDBList/getBusinessDBList', {
        page: page,
        pageSize: pageSize,
        query: query,
        titleNo: titleNo,
        adminNo: adminNo,
    })
}
export function fetchDBAdmins(store) {
    return axios.post('/team/businessDBList/getDBAdmins', {
        store: store,
    })
}

export function fetchBusinessList(page, pageSize, query, adminNo, title, receiveType) {
    return axios.post('/team/business/get_business_list', {
        page: page,
        pageSize: pageSize,
        query: query,
        adminNo: adminNo,
        title: title,
        receiveType: receiveType,
    })
}