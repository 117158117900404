import {all, fork, takeLatest} from 'redux-saga/effects';
import {setUserInfoSaga} from "./userInfo";
import {getBusinessDBList, getDBAdmins} from "../reducers/businessDB";
import {getBusinessDBListSaga, getDBAdminsSaga} from "./businessDB";
import {getBusinessList} from "../reducers/business";
import {getBusinessListSaga} from "./business";
import {getTotalCount, searchPhone} from "../reducers/phoneCheck";
import {getTotalCountSaga, searchPhoneSaga} from "./phoneCheck";

export function* watcherSaga() {
    yield all([
        fork(setUserInfoSaga)
    ])

    yield takeLatest(getTotalCount.type, getTotalCountSaga)
    yield takeLatest(searchPhone.type, searchPhoneSaga)

    //BusinessDB
    yield takeLatest(getBusinessDBList.type, getBusinessDBListSaga)
    yield takeLatest(getDBAdmins.type, getDBAdminsSaga)

    //BusinessDB
    yield takeLatest(getBusinessList.type, getBusinessListSaga)

}