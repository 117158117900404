import {createSlice} from "@reduxjs/toolkit";

export const businessSlice = createSlice({
    name: 'business',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        query: "",
        admins: [],
        admin: 0,
        titles: [],
        title: 0,
        receiveType: 0,
    },
    reducers: {
        getBusinessList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
            state.admin = action.payload.admin
            state.title = action.payload.title
            state.receiveType = action.payload.receiveType
        },
        setBusinessList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.memberList = action.payload.memberList;
            state.admins = action.payload.admins;
            state.titles = action.payload.titles;
        },
        failedGetBusinessList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
    },
});

export const {
    getBusinessList, setBusinessList, failedGetBusinessList,
    setCheckedInputs,
} = businessSlice.actions;

export default businessSlice.reducer