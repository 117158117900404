import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {setCheckedInputs} from "../redux/reducers/businessDB";
import axios from "axios";
import moment from "moment";


const BusinessDBList = ({state, onRefresh}) => {

    const dispatch = useDispatch()
    const [master, setMaster] = useState(0);

    const [teamLeader, setTeamLeader] = useState(0);
    const [teamMember, setTeamMember] = useState(0);
    const [sendTitle, setSendTitle] = useState("0");


    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    const sendBusinessDBList = (e) => {
        if (teamLeader !== 0) {

            axios.post('/admin/businessDBList/sendBusinessDB', {
                sendDBNos: state.checkedInputs,
                sendTitle: sendTitle,
                admin_no: teamLeader
        }).then((res) => {
            if (res.data.isUpdated) {
                alert("전송완료")
                window.location.reload();
            } else if(res.data.error) {
                alert(res.data.error)
            } else {
                alert("전송실패")
            }
        })

        } else {
            alert("관리자 선택해주세요")
        }
    }

    function getOverlapCount(overlaps) {
        let count = 0;

        for(const overlap of overlaps) {
            count += overlap.overlap_count
        }
        return count
    }

    return <div>

        <div className="content border excel_body">
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>
                        <th style={{width: "8%"}}>번호</th>
                        <th>연락처</th>
                        <th>중복횟수</th>
                        <th>DB명</th>
                        <th>분배일</th>

                    </tr>
                    </thead>
                    <tbody key={"list_tbody"}>

                    {state.memberList && state.memberList.map((file, index) => (
                        <>
                            <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox"
                                           checked={state.checkedInputs.includes(file.no)}
                                           onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                                </td>
                                <td style={{fontSize: "13px"}}>{file.no}</td>
                                <td>
                                    <p>{file.member_upload?.phone}</p>
                                </td>
                                <td>{file.member_upload.overlaps.length > 0? getOverlapCount(file.member_upload.overlaps): 0}</td>
                                <td>{file.businessDBTitle.title}</td>
                                <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            </tr>
                        </>
                    ))}

                    </tbody>
                </table>
            </div>
        </div>
    </div>


}

export default BusinessDBList