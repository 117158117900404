import React from 'react';
import {useSelector} from "react-redux";
import {persistor} from "../index";
import {Link} from "react-router-dom";

const Header = () => {

    const userInfo = useSelector((state) => state.userInfo);
    const Home = () => {
        window.location.replace('/')
    }

    const purge = async () => {
        await persistor.purge();
    }

    const onLogoutHandler = (e) => {
        purge()
    }

    function gradeColor (grade) {
        switch (grade) {
            case 1 : return <div className="mx-1" style={{color: "black", fontWeight: "bold"}}>팀원</div>
            case 2 : return <div className="mx-1" style={{color: "green", fontWeight: "bold"}}>팀장</div>
            case 3 : return <div className="mx-1" style={{color: "purple", fontWeight: "bold"}}>마스터관리자</div>
            default : return grade
        }
    }

    return (
        <div className="header">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="logo mt-1 px-4" onClick={Home} style={{cursor: "pointer"}}>
                        {userInfo.type !== 0 ? "영업관리" : "연락처"}
                    </div>
                    <div className="my-auto menu">
                        <nav className="navbar navbar-expand-sm mt-1">
                            <div className="navbar-collapse collapse justify-content-center adminMenu">
                                <ul className="navbar-nav">
                                    <li>
                                        <Link className="nav-link" to="/">
                                            홈
                                        </Link>
                                    </li>
                                    {userInfo.type === 1?
                                        <>
                                            {userInfo.grade > 1?
                                            <li>
                                                <Link className="nav-link" to="/user/businessDBList">
                                                    미할당목록
                                                </Link>
                                            </li>
                                            : <></> }
                                            <li>
                                                <Link className="nav-link" to="/user/businessList">
                                                    영업목록
                                                </Link>
                                            </li>
                                        </>
                                        : <></>
                                    }
                                    {userInfo.type === 2?
                                        <>
                                    <li>
                                        <Link className="nav-link" to="/user/memberList">
                                            본사회원목록
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/user/memberComplete">
                                            본사완료목록
                                        </Link>
                                    </li>
                                        </>
                                        :<></>
                                    }
                                    {userInfo.type === 3?
                                        <>
                                            <li>
                                                <Link className="nav-link" to="/user/businessDBList">
                                                    영업디비목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/businessList">
                                                    영업회원목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/businessReceive">
                                                    영업수신완료
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/businessComplete">
                                                    영업완료목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/memberList">
                                                    본사회원목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/memberComplete">
                                                    본사완료목록
                                                </Link>
                                            </li>
                                        </>
                                        : <></>
                                    }
                                    <li>
                                        <Link className="nav-link" to="/board/notice">
                                            공지사항
                                        </Link>
                                    </li>
                                    {userInfo.type === 0 && (
                                        <li>
                                            <Link className="nav-link" to="/setting/phoneCheck">
                                                연락처검색
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                                {userInfo.grade > 1 ?
                                <div className="dropdown ms-3">
                                    <Link className="nav-link dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        환경설정
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link className="dropdown-item" to="/setting/admin">
                                                관리자관리
                                            </Link>
                                        </li>
                                        {userInfo.grade > 2 ?
                                            <>
                                        <li>
                                            <Link className="dropdown-item" to="/setting/store">
                                                영업점관리
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/setting/member_upload">
                                                본사파일 업로드
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/setting/business_upload">
                                                영업파일 업로드
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/setting/phoneCheck">
                                                연락처확인
                                            </Link>
                                        </li>
                                            </>
                                            : <></> }

                                    </ul>
                                </div>
                                    : <></> }
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="my-auto">
                    <div className="d-flex justify-content-center mt-1 me-2">
                        {userInfo.id} 님
                        <span>{gradeColor(userInfo.grade)}</span>
                    </div>
                    <div className="d-flex justify-content-center mt-1 me-2">
                        <button className="btn btn-sm btn-warning" style={{width: "110px"}}
                                onClick={onLogoutHandler}>로그아웃</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;