import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ProgressModal from "../components/common/ProgressModal";
import {getTotalCount, searchPhone} from "../redux/reducers/phoneCheck";


const PhoneCheckContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.phoneCheck)
    const [upload, setUpload] = useState([]);
    const [query, setQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getTotalCount())
    }, [])

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        if(query.length < 11) {
            alert("전화번호 자리 수 를 더 입력해주세요 (9자리 이상)")
        }
        else {
            dispatch(searchPhone(query))
        }

    }

    const memberType = (type) => {
        switch(type) {
            case 0 :
                return "본사회원"
            case 1 :
                return "영업 문자발신완료"
            case 2 :
                return "블랙리스트"
            case 3 :
                return "알림톡 발신회원"
            case 4 :
                return "문자 발신회원"
            default : return type
        }
    }

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="mb-2">

            {!state.isLoading && (
                <>
                    <div className="d-flex justify-content-between">
                        <span> </span>
                        <div className="d-flex">
                            <input type="text" className="form-control" maxLength="15" placeholder="번호를 입력해주세요 ex)010-1234-5678"
                                   value={query} onChange={queryHandler} onKeyDown={inputPress} style={{
                                width: "400px"
                            }}/>
                            <button type="button" className="btn btn-secondary mx-1" style={{width: "100px"}}
                                    onClick={() => searchHandler(query)}>검색</button>
                        </div>
                    </div>
                    <div className="d-flex my-2">

                    </div>
                    <div>
                        {state.isSearchLoading && (
                            <p>검색중..</p>
                        )}
                        <div>
                            <p>검색결과</p>
                            {state.phoneList.length > 0? state.phoneList.map((item, index) => (
                                <p className="m-1" key={item.no}>{`${memberType(item.member_type)} ${item.phone} , ${item.file_name}`}</p>
                            )) : <p>검색결과가 없습니다.</p>}
                        </div>

                    </div>
                </>
            )}

        </div>

    </div>
}

export default PhoneCheckContainer;