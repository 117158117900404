import React, {useEffect, useState} from "react";
import axios from "axios";
import {NumericFormat} from 'react-number-format';
import moment from "moment";
import {Link} from "react-router-dom";
import commonInfo from "../config/commonInfo";
import {useSelector} from "react-redux";

const Home = () => {

    const [storeList, setStoreList] = useState([]);
    const [businessState1, setBusinessState1] = useState(0);
    const [businessState2, setBusinessState2] = useState(0);
    const [businessState3, setBusinessState3] = useState(0);
    const [businessState4, setBusinessState4] = useState(0);
    const [businessState5, setBusinessState5] = useState(0);
    const [businessState6, setBusinessState6] = useState(0);
    const [businessState7, setBusinessState7] = useState(0);
    const [businessState8, setBusinessState8] = useState(0);
    const [totalBusinessState1, setTotalBusinessState1] = useState(0);
    const [totalBusinessState2, setTotalBusinessState2] = useState(0);
    const [totalBusinessState3, setTotalBusinessState3] = useState(0);
    const [totalBusinessState4, setTotalBusinessState4] = useState(0);
    const [totalBusinessState5, setTotalBusinessState5] = useState(0);
    const [totalBusinessState6, setTotalBusinessState6] = useState(0);
    const [totalBusinessState7, setTotalBusinessState7] = useState(0);
    const [totalBusinessState8, setTotalBusinessState8] = useState(0);
    const [businessSum, setBusinessSum] = useState(0);
    const [totalBusinessSum, setTotalBusinessSum] = useState(0);
    const [memberReceive, setMemberReceive] = useState(0);
    const [memberUnReceive, setMemberUnReceive] = useState(0);

    const [noticeList, setNoticeList] = useState([]);

    const userInfo = useSelector((state) => state.userInfo);

    const getReceive = () => {
        if(userInfo.type !== 0) {
            axios.post('/team/home/get_receive', {
                grade: userInfo.grade,
                admin_no: userInfo.userNo
            }).then((res) => {
                setStoreList(res.data.findStore)
                setBusinessState1(res.data.businessState1)
                setBusinessState2(res.data.businessState2)
                setBusinessState3(res.data.businessState3)
                setBusinessState4(res.data.businessState4)
                setBusinessState5(res.data.businessState5)
                setBusinessState6(res.data.businessState6)
                setBusinessState7(res.data.businessState7)
                setBusinessState8(res.data.businessState8)
                setBusinessSum(res.data.businessState2
                    + res.data.businessState3
                    + res.data.businessState4
                    + res.data.businessState5
                    + res.data.businessState6
                    + res.data.businessState7
                    + res.data.businessState8
                )
                if(userInfo.grade === 2) {
                    setTotalBusinessState1(res.data.totalBusinessState1)
                    setTotalBusinessState2(res.data.totalBusinessState2)
                    setTotalBusinessState3(res.data.totalBusinessState3)
                    setTotalBusinessState4(res.data.totalBusinessState4)
                    setTotalBusinessState5(res.data.totalBusinessState5)
                    setTotalBusinessState6(res.data.totalBusinessState6)
                    setTotalBusinessState7(res.data.totalBusinessState7)
                    setTotalBusinessState8(res.data.totalBusinessState8)
                    setTotalBusinessSum(res.data.totalBusinessState2
                        + res.data.totalBusinessState3
                        + res.data.totalBusinessState4
                        + res.data.totalBusinessState5
                        + res.data.totalBusinessState6
                        + res.data.totalBusinessState7
                        + res.data.totalBusinessState8
                    )
                }
                setMemberReceive(res.data.memberReceive)
                setMemberUnReceive(res.data.memberUnReceive)

            })
        }

    }
    useEffect(getReceive, [])

    const getNoticeList = () => {
        axios.get('/team/home/get_notice_list')
            .then((res) => {
                setNoticeList(res.data.noticeList)
            })
    }
    useEffect(getNoticeList, [])

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    const memberSum = memberReceive + memberUnReceive

    return (
        <div className="p-2">
            홈
            <div className="container-fluid">
                {userInfo.type === 1 && (
                    <>
                        <div className="d-flex my-2">
                            <span className="m2-4">-오늘개인통화</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="bg-dark" style={{width: "10%"}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                     <NumericFormat value={businessSum} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">전체통화</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[5].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState6} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">나중에전화</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[6].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState7} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">결번/정지</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[4].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState5} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">바로끊음</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[7].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState8} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">기존</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[1].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState2} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">부재중</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[2].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState3} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">통화완료</span>
                                </div>
                            </div>

                            <div style={{width: "10%", backgroundColor: receiveList[3].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState4} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">문자발송</span>
                                </div>
                            </div>

                            <div style={{width: "10%", backgroundColor: receiveList[0].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={businessState1} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">대기</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {userInfo.grade === 2 && (
                    <>
                        <div className="d-flex my-2">
                            <span className="m2-4">-오늘지점통화</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="bg-dark" style={{width: "10%"}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                     <NumericFormat value={totalBusinessSum} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">전체통화</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[5].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState6} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">나중에전화</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[6].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState7} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">결번/정지</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[4].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState5} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">바로끊음</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[7].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState8} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">기존</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[1].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState2} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">부재중</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[2].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState3} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">통화완료</span>
                                </div>
                            </div>

                            <div style={{width: "10%", backgroundColor: receiveList[3].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState4} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">문자발송</span>
                                </div>
                            </div>
                            <div style={{width: "10%", backgroundColor: receiveList[0].color}}>
                                <div className="ms-3">
                                <span className="fs-1 text-white">
                                    <NumericFormat value={totalBusinessState1} displayType={'text'} thousandSeparator={true}/>
                                </span>
                                </div>
                                <div className="mx-3 pb-3">
                                    <span className="fs-6 text-white">대기</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="mt-2">
                    <span>공지사항</span>
                    <div className="my-2">
                        <table className="table table-bordered border-dark text-center align-middle">
                            <thead className="table-primary">
                            <tr>
                                <th style={{width: "90%"}}>제목</th>
                                <th style={{width: "10%"}}>작성일자</th>
                            </tr>
                            </thead>
                            {noticeList && noticeList.map((notice, index) => (
                            <tbody key={notice.no}>
                            <tr>
                                <td>
                                    <Link className="nav-link" to={"#noticeTitle" + index} data-bs-toggle="collapse"
                                          aria-expanded="true" aria-controls={"noticeTitle" + index}>
                                        {notice.title}
                                    </Link>
                                </td>
                                <td>{moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            </tr>
                            <tr className="collapse" id={"noticeTitle" + index}>
                                <td colSpan="3" className="text-start">
                                    {notice.cont}
                                    <br></br>
                                    {notice.image !== "0"?
                                        <img src={commonInfo.imgUrl + notice.image} alt="공지사항" />
                                        :""}
                                </td>
                            </tr>
                            </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Home;